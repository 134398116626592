#editorial {
    .section-mailForm {
        padding-top: 2rem;
        line-height: 1.1;

        form {
            margin-top: 40px;
            margin-bottom: 20px;
            font-size: 0.88rem;

            label {
                margin-bottom: 5px;
            }

            textarea {
                height: auto;
            }

            .horizontal-fields, .duplicated-field {
                display: flex;
                flex-direction: row;

                input[type="button"], button {
                    height: auto;
                    width: 43px;
                    padding: 0 !important;
                    margin-left: 10px;
                    margin-right: 0;
                    &:disabled {
                        cursor: default;
                    }
                }
            }

            .duplicated-field {
                margin-top: 10px;
            }

            .form-group {
                margin-bottom: 30px;

                div[class^="col-"]:not(.submit-action) {
                    &:not(:first-child) {
                        @media only screen and (max-width:768px) {
                            margin-top: 30px
                        }
                    }
                }
            }

            .info {
                font-size: 0.85em;
                margin-top: 8px;
            }

            .licence-agreement {
                a {
                    font-weight: inherit;
                    font-size: inherit;
                }
                line-height: 1.3;

                .custom-control-label {
                    &:before {
                        top: 0;
                    }
                    &:after {
                        top: 0;
                    }
                }
            }
        }

        a {
            font-size: 1rem;
            font-weight: $weight-regular;
        }
    }
}
