#editorial {
    .container {
        .main-content {
            section {
                width: $editorial-container-width;
                @media only screen and (max-width: $editorial-container-width) {
                    width: 100%;
                    &:not(.wide) {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }

            .section-double-text {
                div {
                    @media only screen and (min-width: $editorial-container-width) {
                        margin-left: 10px;
                        margin-right: 10px;
                        &:first-child {
                            margin-left: 0px;
                        }
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                    @media only screen and (max-width: $editorial-container-width) {
                        padding-left: 10px;
                        padding-right: 10px;

                        &:first-child {
                            padding-left: 0px;
                        }
                        &:last-child {
                            padding-right: 0px;
                        }
                    }
                    @media only screen and (max-width: 768px) {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }

            .section-double-text {
                @media only screen and (min-width: $editorial-container-width) {
                    div {
                        width: 355px;
                    }
                }
            }

            .section-text {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;

                @media only screen and (min-width: $editorial-container-width) {
                    div {
                        padding-left: calc(50% - #{$editorial-container-width} / 2);
                        padding-right: calc(50% - #{$editorial-container-width} / 2);
                    }
                }
            }

            .section-img-text {
                .image-wrapper {
                    &.left {
                        @media only screen and (min-width: $editorial-container-width) {
                            float: left;
                            margin-right: 20px;
                        }
                    }
                    &.right {
                        @media only screen and (min-width: $editorial-container-width) {
                            float: right;
                            margin-left: 20px;
                        }
                    }

                    img, .caption {
                        max-width: $editorial-image-max-width;
                        @media only screen and (max-width: $editorial-container-width) {
                            max-width: 100%;
                        }
                    }

                    .caption {
                        white-space: normal;
                    }
                }
            }
            .section-double-image
            {
                .col-img {
                    width: $editorial-2-column-width;
                }
            }
            .section-triple-image
            {
                .col-img {
                    width: $editorial-3-column-width;
                }
            }
            .section-double-image, .section-triple-image {
                .col-img {
                    margin-right: 10px;
                    margin-left: 10px;
                    &:first-child {
                        margin-left: 0px;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                    @media only screen and (max-width: $editorial-container-width) {
                        width: 100%;
                        &:not(:last-child) {
                            margin-bottom: 50px;
                        }
                    }

                    img {
                        @media only screen and (min-width: $editorial-container-width) {
                            max-width: $editorial-container-width
                        }
                        @media only screen and (max-width: $editorial-container-width) {
                            max-width: 100%;
                        }
                    }

                    @media only screen and (max-width: $editorial-container-width) {
                        margin-left: 0%;
                        margin-right: 0%;
                        text-align: center;
                    }
                }
            }
            .section-tiles {
                ul {
                    li {
                        margin-right: 10px;
                        margin-left: 10px;
                        &:first-child {
                            margin-left: 0px;
                        }
                        &:last-child {
                            margin-right: 0px;
                        }
                    }

                }
                &.cols-3 {
                    @media only screen and (min-width: 769px) {
                        ul {
                            li {
                                figure {
                                    @include width-height(230px, 163px);
                                    figcaption {
                                        font-size: 0.700em;
                                        height: 49px;
                                        padding: 5px 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
