@import "./contactForm.scss";
@import "./page.scss";
@import "./gallery.scss";
@import "./homepage.scss";

#editorial, #home {
    main {
        .container {
            font-weight: $editorial-weight;

            b, strong {
                font-weight: $editorial-weight-bold;
            }
            overflow: visible;
            padding: 0;
            color: $editorial-content-text;

            .main-content {
                section {
                    margin:auto;

                    &.wide {
                        width: 100%;
                    }
                }
                float: none;
            }

            .editorial-navigation {
                height: 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                position: absolute;
                z-index: 1;
                width: 100%;
            }

            article {
                .main-article {
                    header {
                        display: block;
                        background: none;
                        position: relative;
                        z-index: 0;
                        padding: 0;
                        margin-bottom: 60px;
                    }
                    ul li {
                        list-style: square;
                        list-style-position: inside;
                    }
                    ol li {
                        list-style-type: decimal;
                        list-style-position: inside;
                    }
                    h3 {
                        color: $editorial-content-title-one;
                    }
                    h4 {
                        color: $editorial-content-title-two;
                    }
                    img {
                        display:  inline-block;
                    }
                    .caption {
                        font-size: 0.65em;
                        font-style: italic;
                        font-weight: $weight-regular;
                        text-align: center;
                        color: $editorial-content-image-caption;
                        line-height: 16px;
                        padding: 10px;
                    }
                    section {
                        clear: both;
                        padding-top: 24px;
                        padding-bottom: 24px;
                        color: $editorial-content-text;

                        form {
                            label {
                                font-weight: $weight-regular;
                            }
                        }

                        &:first-of-type {
                            &:not(.wide) {
                                padding-top: 90px;
                            }
                            &.section-collaborative-indexation {
                                padding-top: 30px;
                            }
                        }
                        &.section-title {
                            padding-bottom: 0px;
                            .title-article {
                                width: 100%;
                            }
                        }
                        &.section-video {
                            video {
                                margin: 0 auto;
                                display: inherit;
                                max-width: 100%;
                            }
                            .video-gallica {
                                text-align: center;
                            }

                            iframe {
                                background-color: black;
                                margin-bottom: -10px;

                                &.hidden {
                                    visibility: hidden;
                                }
                            }
                        }
                        .external-video-image {
                            position: relative;
                            display: inline-block;
                            cursor: pointer;

                            &:before {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                -webkit-transform: translate(-50%, -50%);
                                content: "\f01d";
                                font-family: FontAwesome;
                                font-size: 100px;
                                color: #fff;
                                opacity: .8;
                                text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
                            }
                            &:hover:before {
                                color: #eee;
                            }

                            img {
                                max-height: 416px;
                                max-width: 100%;
                            }
                        }

                        &.section-banner {
                            position: relative;
                            display: flex;
                            align-items: center;
                            @include width-height(100%, 612px);
                            @media only screen and (max-width: 1024px) {
                                height: 327px;
                            }
                            overflow: hidden;
                            padding: 0;
                            &.section-bannerTitle {
                                background-size: cover;
                                background-position: center;

                                .text-wrapper {
                                    margin: 48px;
                                    max-height: 516px;
                                    width: 100%;
                                    display: flex;

                                    &.left {
                                        text-align: left;
                                        justify-content: flex-start;
                                        .title {
                                            margin-left: 5%;
                                        }
                                    }

                                    &.center {
                                        text-align: center;
                                        justify-content: center;
                                        button {
                                            margin-right: auto;
                                            margin-left: auto;
                                        }
                                    }

                                    &.right {
                                        text-align: right;
                                        justify-content: flex-end;
                                        .title {
                                            margin-right: 5%;
                                        }
                                        button {
                                            margin-left: auto;
                                            margin-right: 0;
                                        }
                                    }

                                    .title {
                                        color: $editorial-content-banner;
                                        max-width: 50%;
                                        text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.7);
                                        @media only screen and (max-width: 1024px) {
                                            max-width: 75%;
                                        }

                                        span {
                                            font-family: $font-secondary;
                                            font-weight: $weight-bold;
                                            line-height: 52px;
                                            font-size: 2.750rem;
                                            @media only screen and (max-width: 1024px) {
                                                font-size: 2.25rem;
                                                line-height: 42px;
                                            }
                                            @media only screen and (max-width: 650px) {
                                                font-size: 1.75rem;
                                                line-height: 32px;
                                            }
                                        }

                                        button {
                                            display: block;
                                            margin-top: 25px;
                                            font-size: 0.7em;
                                        }
                                    }

                                }
                            }
                            .section-bannerText {
                                background-color: $color-11;
                                width: 100%;
                                height: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    margin: 0 auto;
                                }
                                .text-wrapper {
                                    opacity: 0.8;
                                    margin : 48px;
                                    max-height: 516px;
                                    @media only screen and (max-width: 1024px) {
                                        margin : 24px;
                                        max-height: 279px;
                                    }
                                    width: 25%;
                                    background-color: white;
                                    position: absolute;
                                    @media only screen and (max-width: 768px) {
                                        width: 50%;
                                    }
                                    &.top {
                                        top: 0;
                                    }
                                    &.left {
                                        left: 0
                                    }
                                    &.bottom {
                                        bottom: 0;
                                    }
                                    &.right {
                                        right: 0
                                    }
                                    .text {
                                        &:hover {
                                            overflow: auto;
                                        }
                                        max-height: 452px;
                                        margin : 32px 0 32px 0;
                                        padding : 0 32px 0 32px;
                                        @media only screen and (max-width: 1024px) {
                                            max-height: 247px;
                                            margin : 16px 0 16px 0;
                                            padding : 0 16px 0 16px;
                                        }
                                        overflow: hidden;
                                    }
                                }
                            }
                            &.small-height {
                                @include width-height(100%, 350px);
                                @media only screen and (max-width: 1024px) {
                                    height: 187px;
                                }

                                .text-wrapper {
                                    max-height: 254px;
                                    @media only screen and (max-width: 1024px) {
                                        max-height: 139px;
                                    }
                                    .text {
                                        max-height: 190px;
                                        @media only screen and (max-width: 1024px) {
                                            max-height: 107px;
                                        }
                                    }
                                }
                            }
                            #banner-info {
                                visibility: hidden;
                                position: absolute;
                            }
                        }
                        &.video {
                            margin-bottom:48px;
                            canvas {
                                @include width-height(100%, 524px);
                                overflow:hidden;
                            }
                        }
                        &.section-img-text {
                            .image-wrapper {
                                text-align: center;
                                margin: 8px 0px 8px 0px;
                            }
                        }
                        &.section-image {
                            img, div.caption {
                                max-width: 100%;
                            }
                        }
                        &.section-tiles {
                            @media only screen and (max-width: 768px) {
                                width: 355px;
                                ul {
                                    li {
                                        margin-left: 0px;
                                        margin-right: 0px;
                                    }
                                }
                            }

                            &.section-publication-tiles {
                                .clearfix:nth-child(2) {
                                    margin-top: 30px;

                                    @media only screen and (max-width: $editorial-container-width) {
                                        margin-top: 50px;
                                    }
                                }
                            }

                            ul {
                                @include dflexTotal();
                                li {
                                    @media only screen and (max-width: 768px) {
                                        &:not(:last-child) {
                                            margin-bottom: 50px;
                                        }
                                    }

                                    list-style-type: none;

                                    figure {
                                        position: relative;
                                        margin:0;
                                        overflow: hidden;

                                        @include width-height(355px, 250px);
                                        @media only screen and (max-width: $editorial-container-width) {
                                            @include width-height(320px, 225px);
                                        }

                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: cover;

                                        &.tile-serie, &.tile-classificationScheme {

                                            background-image: url('./img/editorial/tiles/classification-scheme.png');
                                        }

                                        &.tile-advancedSearchForm {
                                            background-image: url('./img/editorial/tiles/advanced-search-form.png');
                                        }

                                        &.tile-page {
                                            background-image: url('./img/editorial/tiles/page.png');
                                        }

                                        &.tile-document {
                                            background-image: url('./img/editorial/tiles/document.png');
                                        }

                                        &.tile-record {
                                            background-image: url('./img/editorial/tiles/record.png');
                                        }

                                        figcaption {
                                            @include width-height(100%,75px);
                                            @media only screen and (max-width:$editorial-container-width) {
                                                @include width-height(100%,67px);
                                            }

                                            @include absolute-bottomleft (0, 0);
                                            padding: 10px 20px;
                                            background-color: $editorial-content-tile-heading-background;
                                            line-height: 30px;
                                            display: flex;
                                            align-items: center;

                                            h2 {
                                                font-size: 1em;
                                                color: $editorial-content-tile-heading;
                                                font-weight: $weight-bold;
                                                overflow:hidden;
                                                white-space: nowrap;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                    }

                                    &.publication-tile {
                                        width: 345px;
                                        height: 350px;
                                        text-align: center;
                                        border: 1px solid $editorial-publication-tile-border;
                                        display: flex;
                                        flex-direction: column;
                                        position: relative;
                                        cursor: pointer;
                                        margin-bottom: 25px;

                                        &:first-child {
                                            margin-left: 15px;
                                        }

                                        &:last-child {
                                            margin-right: 15px;
                                        }

                                        @media only screen and (max-width: 768px)
                                        {
                                            &:first-child {
                                                margin-left: 0;
                                            }

                                            &:last-child {
                                                margin-right: 0;
                                            }
                                        }

                                        &:hover {
                                            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
                                        }

                                        &.publication-network-facebook {

                                            .publication-heading {
                                                &:before {
                                                    content: "\f230";
                                                    color: #4267b2;
                                                }
                                            }

                                            .publication-title {
                                                .publication-title-value {
                                                    color: #4267b2;
                                                }
                                            }

                                            .publication-text {
                                                font-family: Helvetica, Arial, sans-serif;
                                                font-size: 15px;
                                                font-weight: 400;
                                                line-height: 20px;
                                            }
                                        }

                                        .publication-text {
                                            font-size: .88rem;
                                            line-height: 17px;
                                            margin: 10px 15px 15px 15px;
                                            text-align: left;
                                            overflow: hidden;

                                            &.has-image {
                                                max-height: 80px;
                                            }
                                        }

                                        .publication-image {
                                            flex: 1;
                                            background-color: $editorial-publication-tile-background;

                                            &.video {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                &:before {
                                                    content: "\f144";
                                                    font-family: 'fontawesome-webfont';
                                                    position: absolute;
                                                    font-size: 4em;
                                                    z-index: 10;
                                                    opacity: .8;
                                                }
                                            }

                                            figure {
                                                height: 100%;
                                                width: 343px;
                                                line-height: initial;

                                                @media only screen and (max-width: $editorial-container-width) {
                                                    width: 318px;
                                                }

                                                &:not(.has-source):before {
                                                    font-family: FontAwesome;
                                                    font-size: 50px;
                                                    position: relative;
                                                    top: calc(50% - 25px);
                                                }
                                            }
                                        }

                                        .publication-heading {
                                            text-align: left;
                                            margin: 15px 15px 10px 15px;
                                            height: 30px;
                                            display: flex;

                                            .publication-title {
                                                line-height: 16px;
                                                font-size: 0.85rem;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;

                                                .publication-title-value {
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    font-weight: $weight-bold;
                                                }
                                            }

                                            &:before {
                                                font-family: FontAwesome;
                                                margin-right: 10px;
                                                font-size: 35px;
                                                height: 35px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.section-audio {
                            audio {
                                width: 60%;
                                outline: none;
                            }
                        }
                    }
                    .icon-external-link {
                        margin-left: 5px;
                        margin-right: 1px;
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}
