#home {
    .container {
        .main-content {
            section {
                width: $editorial-home-container-width;
                @media only screen and (max-width: $editorial-home-container-width) {
                    width: 100%;
                    &:not(.wide) {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }

            .section-double-text, .section-triple-text {
                div {
                    @media only screen and (min-width: $editorial-home-container-width) {
                        margin-left: 15px;
                        margin-right: 15px;
                        &:first-child {
                            margin-left: 0px;
                        }
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                    @media only screen and (max-width: $editorial-home-container-width) {
                        padding-left: 15px;
                        padding-right: 15px;

                        &:first-child {
                            padding-left: 0px;
                        }
                        &:last-child {
                            padding-right: 0px;
                        }
                    }
                    @media only screen and (max-width: 768px) {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }
            .section-triple-text {
                @media only screen and (min-width: $editorial-home-container-width) {
                    div {
                        width: 355px;
                    }
                }
            }
            .section-double-text {
                @media only screen and (min-width: $editorial-home-container-width) {
                    div {
                        width: 547px;
                    }
                }
            }
            .section-text {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;

                @media only screen and (min-width: $editorial-home-container-width) {
                     div {
                        padding-left: calc(50% - #{$editorial-home-container-width} / 2);
                        padding-right: calc(50% - #{$editorial-home-container-width} / 2);
                     }
                }
             }

            .section-img-text {
                .image-wrapper {
                    @media only screen and (min-width: $editorial-home-container-width) {
                        max-width: 50%;
                    }
                    &.left {
                        @media only screen and (min-width: $editorial-home-container-width) {
                            float: left;
                            margin-right: 30px;
                        }
                    }
                    &.right {
                        @media only screen and (min-width: $editorial-home-container-width) {
                            float: right;
                            margin-left: 30px;
                        }
                    }

                    img {
                        max-width: $editorial-home-image-max-width;
                        @media only screen and (max-width: $editorial-home-container-width) {
                            max-width: 100%;
                        }
                    }
                }
            }
            .section-double-image
            {
                .col-img {
                    width: $editorial-home-2-column-width;
                }
            }
            .section-triple-image
            {
                .col-img {
                    width: $editorial-home-3-column-width;
                }
            }
            .section-double-image, .section-triple-image {
                .col-img {
                    margin-right: 14.5px;
                    margin-left: 14.5px;
                    &:first-child {
                        margin-left: 0px;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                    @media only screen and (max-width: $editorial-home-container-width) {
                        width: 100%;
                        &:not(:last-child) {
                            margin-bottom: 50px;
                        }
                    }

                    img {
                        @media only screen and (min-width: $editorial-home-container-width) {
                            max-width: $editorial-home-container-width
                        }
                        @media only screen and (max-width: $editorial-home-container-width) {
                            max-width: 100%;
                        }
                    }

                    @media only screen and (max-width: 736px) {
                        margin-left: 0%;
                        margin-right: 0%;
                        text-align: center;
                    }
                }
            }
            .section-tiles {
                ul {
                    li {
                        margin-right: 15px;
                        margin-left: 15px;
                        &:first-child {
                            margin-left: 0px;
                        }
                        &:last-child {
                            margin-right: 0px;
                        }
                    }

                }
                &.cols-3 {
                    @media only screen and (min-width: 769px) and (max-width: $editorial-home-container-width) {
                        ul {
                            li {
                                figure {
                                    @include width-height(232px, 163px);
                                    figcaption {
                                        font-size: 0.700em;
                                        height: 49px;
                                        padding: 5px 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
