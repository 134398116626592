.gallery-container {
    width: 100%;
    button:focus {
        outline: auto;
    }
    .gallery {
        text-align: center;
        position: relative;

        .gallery-image {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $editorial-content-gallery-image-background;
            height: 480px;
            img {
                max-height: 480px;
                width: 100%;
            }
            @media (max-width: 767px) {
                height: 275px;
                a {
                    img {
                        max-height: 275px;
                    }
                }
            }

            .arrow-navigation {
                display: none;
                position: absolute;
                font-size: 1.4rem;
                color: $editorial-content-gallery-arrow;
                background-color: $editorial-content-gallery-arrow-background;
                border-radius: 50%;
                width: 2.8rem;
                height: 2.8rem;
                top: 50%;
                transform: translateY(-50%);

                &.previous {
                    left: 2%;
                    padding: 0.1rem 0.2rem 0 0;
                }

                &.next {
                    right: 2%;
                    padding: 0.1rem 0 0 0.2rem;
                }

                &:focus:not(:focus-visible)
                {
                    outline: none;
                }
            }
        }

        &:hover {
            .arrow-navigation {
                display: block;
            }
        }

        .gallery-indicators {
            margin: 8px 0;
            .gallery-dot {
                height: 15px;
                width: 15px;
                font-size: 1px;
                margin-right: 10px;
                background-color: $editorial-content-gallery-dot;
                border-radius: 50%;
                display: inline-block;

                &.current {
                    background-color: $editorial-content-gallery-dot-selected;
                }
            }
        }

        .caption-wrapper {
            height: 2rem;
            .caption {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
    }
}

#editorial, #home {
    main {
        .container {
            article {
                .main-article {
                    .gallery-container {
                        .caption {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
