.container {
    .editorial-content {
        line-height: 30px;
        font-size: 1.25rem;
        font-weight: $weight-light;

        .linebreak
        {
            display: block;
            height: 30px;
        }

        section {
            clear: both;
            padding: 24px 0;
        }

        ul li {
            list-style: square;
            list-style-position: inside;
        }

        ol li {
            list-style-type: decimal;
            list-style-position: inside;
        }

        h1 {
            font-family: $font-secondary;
            color: $editorial-content-basis-heading;
            font-weight: $weight-bold;
            font-size: 3.125rem;
            word-wrap: break-word;
        }

        h2 {
            color: $editorial-content-heading;
        }

        a {
            display: inline;
        }
    }
}

.contact {
    .submit-action {
        margin-top: 22px;
    }
}

.reservation {
    .submit-action {
        margin-top: 22px;
    }
}
